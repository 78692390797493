import styled from 'styled-components'
import Button from '@/components/UI/Button/Button.jsx'
import { Title, Caption } from '@/components/UI/Typography/Typography'

const Wrapper = styled.div`
  min-height: calc(100vh - 300px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Heading = styled(Title)`
  position: relative;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
`

const Text = styled(Caption)`
  max-width: 357px;
  margin: 24px auto 40px;
  width: 90%;
`

const ImageWrapper = styled.div`
  max-width: 597px;
  width: 90%;
  margin: 0 auto 48px;
`

const CTA = styled(Button)`
  max-width: 167px;
  margin: 0 auto 120px;
`

export { Wrapper, Heading, Text, ImageWrapper, CTA }
