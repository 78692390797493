import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useContext } from '@/store'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import {
  Wrapper,
  Heading,
  Text,
  ImageWrapper,
  CTA,
} from '@/components/contents/404/404.css'

const NotFoundPage = props => {
  const content = props.data.allPrismicFourofour.edges
  const { locale } = useContext()
  const filteredContent = content.find(e => e.node.lang === locale)
  const specificLanguage = filteredContent.node.data

  return (
    <Layout title={specificLanguage.meta_title}>
      <NavBar />
      <Wrapper>
        <ImageWrapper>
          <Img
            alt={specificLanguage.image.alt}
            fluid={specificLanguage.image.localFile.childImageSharp.fluid}
          />
        </ImageWrapper>
        <Heading>{specificLanguage.title}</Heading>
        <Text>{specificLanguage.subtitle}</Text>
        <CTA appearance='blue' to={specificLanguage.button_url}>
          {specificLanguage.button_text}
        </CTA>
      </Wrapper>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  fragment ImageModel on PrismicImageType {
    localFile {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }

  query fourOFour {
    allPrismicFourofour {
      edges {
        node {
          data {
            button_text
            button_url
            image {
              alt
              ...ImageModel
            }
            subtitle
            meta_title
            title
          }
          lang
        }
      }
    }
  }
`

export default NotFoundPage
